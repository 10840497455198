// What to pass?
// Functions - Pass functions definitions without the ().
// To be able to use THIS inside the funtions, you need to bind it beforehand.
// to do some correctly: define them in the constructor. Use arrow functions or bind(this).
// e.g.: this.callback = () => { ... }
// All other params should be passed without curly brackets {{}}
// We are stringified objects before passing them to react

const RESERVED_KEYS = {
	COMPONENT: 'component',
	DEEP_EQUALITY_SUFFIX: 'Deep',
};

(function () {
	'use strict';
	class ReactComponentController extends Controllers.BaseControllerES6 {
		
		// @ngInject
		constructor($scope, $element, $parse, $injector, $attrs, uuid4,  ReactLoaderService, $stateParams = {}) {
			super($scope, $injector);
			this.__objectType = 'ReactComponentController';
			this.$element = $element;
			
			this.$parse = $parse;
			this.$scope = $scope;
			this.parentScope = $scope.$parent;
			this.$attrs = $attrs;
			this.$injector = $injector;
			this.$stateParams = $stateParams;
			this.reactRenderId = uuid4.generate();
			this.ReactLoaderService = ReactLoaderService;
		}
		
		$postLink() {
			this.registerObservers();
			const props = this.getProps();
			this.ReactLoaderService.loadReactInfraData(props).then(() => {
				return this.ReactLoaderService.getReactInstance();
			}).then(honeybookReact => {
				this.reactRenderFunction = honeybookReact.bootstrap(this.component);
				const _props = this.getProps();
				this.render(_props);
			});
		}

		rerender() {
			const props = this.getProps();
			this.render(props, true);
		}
		
		render(props, isPropUpdate) {
			const routeParams = { match: { params: Object.assign({}, this.$stateParams) } };
			
			if (!this.reactRenderFunction){
				return;
			}
			
			this.reactUnrenderFunction = this.reactRenderFunction(
				this.$element[0],
				this.reactRenderId,
				props,
				routeParams,
				this.$injector,
				isPropUpdate
				);
				
			}
			
			registerObservers() {
				const watchGroupExpressions = Object.keys(this.$attrs.$attr)
				.filter(key => key !== RESERVED_KEYS.COMPONENT || !key.endsWith(RESERVED_KEYS.DEEP_EQUALITY_SUFFIX))
				.reduce((result, key) => {
					result.push(this.$attrs[key]);
					return result;
				}, []);
				this.unsubscribe = this.parentScope.$watchGroup(
					watchGroupExpressions,
					(newValues, oldValues) => {
						if (newValues.every((newValue, index) => oldValues[index] === newValue)) {
							return;
						}
						this.rerender();
					}
					);
				this.unsubscribeFromDeepWatches = Object.keys(this.$attrs.$attr)
					.filter(key => key.endsWith(RESERVED_KEYS.DEEP_EQUALITY_SUFFIX))
					.map(key => {
						const attrKey = this.$attrs[key];
						this.parentScope.$watchCollection(attrKey, (newValue, oldValue) => {
							if (angular.equals(newValue, oldValue)) {
								return;
							}
							this.rerender();
						});
					});
				}
				
				getProps() {
					return Object.keys(this.$attrs.$attr)
					.filter(key => key !== RESERVED_KEYS.COMPONENT)
					.reduce((result, key) => {
						const isDeepEquality = key.endsWith(RESERVED_KEYS.DEEP_EQUALITY_SUFFIX);
						if (isDeepEquality) {
							const propKey = key.replace(RESERVED_KEYS.DEEP_EQUALITY_SUFFIX, '');
							const getValue = angular.copy(this.$parse(this.$attrs[key]));
							result[propKey] = getValue(this.parentScope);
						} else {
							const getValue = this.$parse(this.$attrs[key]);
							result[key] = getValue(this.parentScope);
						}
						return result;
					}, {});
				}
				
				$onDestroy() {
					this.reactUnrenderFunction && this.reactUnrenderFunction();
					this.unsubscribe();
					this.unsubscribeFromDeepWatches.forEach(unsubscribe => unsubscribe && unsubscribe());
				}
			}
			
			Components.ReactComponent = {
				template: '<div></div>',
				bindings: {
					component: '@'
				},
				controller: ReactComponentController
			};
			
		}()); 