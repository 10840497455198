(function () {
    'use strict';

    Controllers.WorkspaceProjectDetailsController = class WorkspaceProjectDetailsController extends Controllers.BaseControllerES6 {

        // @ngInject
        constructor($scope, $injector, EventsManager, $stateParams, UsersManager, EventService, ModalService, $timeout, UserService,
                    AnalyticsService, UiPersistenceService, AppStates, DeviceService, CompaniesManager, _, RescheduleProjectService, StatsigService, FeaturesService) {
            super($scope, $injector);
            this.__objectType = 'WorkspaceProjectDetailsController';

            this.EventService = EventService;
            this.EventsManager = EventsManager;
            this.ModalService = ModalService;
            this.AnalyticsService = AnalyticsService;
            this.UiPersistenceService = UiPersistenceService;
            this.FeaturesService = FeaturesService;
            this.CompaniesManager = CompaniesManager;
            this.AppStates = AppStates;
            this.$timeout = $timeout;
            this._ = _;
            this.RescheduleProjectService = RescheduleProjectService;
            this.UserService = UserService;
            this.isLoading = true;
            this.StatsigService = StatsigService;

            EventsManager.getEvent($stateParams.event_id, true, true).then((project) => {
                this.project = project;
                this.projectLoaded();
            });

            this.workspaceId = $stateParams.workspace_id;
            this.user = UsersManager.getCurrUser();
            this.isMobile = DeviceService.nxSmallBreakpoint();
            this.shouldShowClip = false;

            // This flag need to die. Only open to one user, moved to Statig from Splitio
            StatsigService.isGateEnabled('copy_project_details').then(function(value) {
                this.shouldShowClip = value;
            }.bind(this));

            this.addressAutocompleteEnabled = false;
            StatsigService.isGateEnabled('location-iq-address-autocomplete').then(isGateEnabled => {
                this.addressAutocompleteEnabled = isGateEnabled;
            });


            this.dupConfigTooltip = "Copy project details";
            this._initEdit();

            this.updateProject = (props) => {
                angular.extend(this.project, props);
                this.reRenderProjectKey = JSON.stringify(this.project.dataOnly());
            };

            this.onChange = (props) => {
                // merge attributes to model
                angular.extend(this.project, props);
                return this.EventsManager.updateEventDetails(this.project);
            };
        }

        _initEdit() {
            this.projectTypes = this.user.company.sortedProjectTypes();
            this.projectTypes = this.projectTypes.length ? this.projectTypes : [{label: ''}];
            this.isVenue = this.user.isVenueVendor();
            this.dateLabelType = 'date_label';
            this.locationLabelType = 'location_label';
            this.datePrefix = 'Project Location';
            this.locationPrefix = 'Project Location';
            this.analyticsEventsSource = 'project details tab (workspace)';
            this.multiDateAnalyticsEventsSource = this.analyticsEventsSource + ' multi date';
        }
        
        projectLoaded() {
            this.isEditMode = !this.project.isViewOnlyMode();
            this.selectedProjectType = this.EventService.getProjectTypeByString(this.user.company, this.project.event_type);
            this.hasStartDate = !!this.project.event_date;
            this.shouldApplyBranding = this.user.isClient();
            this.isNewCustomFieldsEnabled = !!this.project.custom_project_fields_v2;
            this.projectDetailsReactVariant = '';
            this.StatsigService.isGateEnabled('ng2react_project_details').then(function(enabled) {
                this.projectDetailsReactVariant = enabled ? 'react' : 'angular';
            }.bind(this));
            if (this.project.event_location || !this.user.company.isConsultant()) {
                this.showMainProjectLocation = true;
                this.isLoading = false;
            } else {
                this.UserService.getIsConsultantABTest().then(isConsultantABTest => {
                    this.isConsultantABTest = isConsultantABTest;
                    this.showMainProjectLocation = !isConsultantABTest;
                    this.isLoading = false;
                });
            }
        }

        onLocationChange(changes) {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_project_location, {source: this.analyticsEventsSource, ab_test_variation: this.addressAutocompleteEnabled ? 'location-iq' : 'google-places'});
            this.onChange({event_location: changes.location});
        }

        onAvailabilityChange(changes) {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_project_availability, {source: this.analyticsEventsSource});
            this.onChange({availability_type: changes.availability});
        }

        onProjectNotesChange() {
            return this.EventsManager.updateCompanyProjectNotes(this.user.company, this.project);
        }

        onProjectTypeChanged(projectType) {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_project_type, {source: this.analyticsEventsSource});
            this.selectedProjectType = projectType;
            this.onChange({event_type: projectType.label});
        }

        onTimezoneChange(timezone) {
            this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_project_timezone, {source: this.analyticsEventsSource});
            this.onChange({event_timezone: timezone});
        }

        openRescheduleModal() {
            if (this.hasStartDate && !this.isMobile) {
                this.$timeout(() => {
                    const datePicker = document.querySelectorAll('.datepicker');
                    if (datePicker) {
                        datePicker.forEach((el) => {
                            el.style.setProperty('display', 'none');
                        });
                    }
                });

                this.RescheduleProjectService.openRescheduleModal(this.project, this.workspaceId, this.project.is_paused, 'Covid-19 toolkit');
            }
        }

        copyToClip(){
            let p = this.project;
            let str = "Project name: " + p.event_name;
            str += this._getFormatedDate(p);
            str += p.event_location ? `\nLocation: ${p.event_location}` : "";
            str += p.event_details ? `\nAbout: ${p.event_details}` : "";
            str += this._getFormatedContacts(p);
            navigator.clipboard.writeText(str);

            this.dupConfigTooltip = "Copied";
            setTimeout(()=>{
                this.dupConfigTooltip = "Copy project details";
            },2000);
        }

        _getFormatedDate(event){
            let str = "";
            if (event.event_date){
                str += `\nProject Date: ${event.event_date}`;
                str += event.event_time_start ? ` at ${event.event_time_start}` : "";
                str += event.event_end_date ? ` - ${event.event_end_date}` : "";
                str += event.event_time_end ? ` at ${event.event_time_end}` : "";
            }
            return str;
        }

        _getFormatedContacts(event){
            let str = "";
            const clients = event.event_users.filter((user)=>user.isClient());
            clients.forEach((client)=>{
                str += `\n${client.full_name}: ${client.phone_number ? client.phone_number : client.email}`;
            });
            return str;
        }

        onDateTimeChange(changes) {

            let updates = {};

            this._.each(changes, (value, type) => {
                updates[type] = value;
                this.AnalyticsService.track(this, 'Change project date', {source: this.analyticsEventsSource, field_type: this._getDateTimeAnalyticEvent(type)});
            });

            this.onChange(updates);
        }

        openNotesFullScreen() {
            this.ModalService.openProjectDetailsNotesModal(this.project, this.user.company);
        }

        onUpdatePublicNote() {
            this.EventsManager.updateEventDetails(this.project);
        }

        sortedList(item, list) {
          return [item,  ...list].sort((a, b) => {
            return a.label < b.label ? -1 : b.label < a.label ? 1 : 0;
          });
        }

        onAddProjectType(projectType) {
            let tag = {
                label: projectType,
                editable: true,
                open_text: false
            };

            this.projectTypes = this.sortedList(tag, this.projectTypes);

            return this.CompaniesManager.updateProjectTypes(this.user.company, this.projectTypes).then((list) => {
                this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.create_project_type, {source: 'project details tab'});
                return list.data.project_types.filter((i) => {
                    return i.label === tag.label;
                });
            });
        }

        createDate() {
            this.EventsManager.createProjectDate(this.project)
                .then((resp) => {
                    this.project.project_dates = resp.data.project_dates;
                });
        }

        formatValue(field){
            return field.type === "date" && !!field.value ? new Date(field.value).toDateString(): field.value;
        }

        onShowMainProjectLocation() {
            this.showMainProjectLocation = true;
        }

        onAddLocation(date) {
            const locationLabel = this._generateLocationLabel();
            this.updateDate({date, props: {location_label: locationLabel}});
        }

        deleteDate(date_id) {
            this.EventsManager.deleteProjectDate(this.project, date_id)
                .then((resp) => {
                    this.project.project_dates = resp.data.project_dates;
                });
        }

        updateDate(params) {
            if(params.props) {
                this._.each(params.props, (value, type) => {
                    this.AnalyticsService.track(this, this.AnalyticsService.analytics_events.edit_date, {source: this.multiDateAnalyticsEventsSource, field_type: this._getDateTimeAnalyticEvent(type)});
                });
                if (params.props.date_label && this._isExistingLabel(this.dateLabelType, params.props.date_label)) {
                    return false;
                }
                if (params.props.location_label && this._isExistingLabel(this.locationLabelType, params.props.location_label)) {
                    return false;
                }
                angular.extend(params.date, params.props);
                this.EventsManager.updateProjectDate(this.project, params.date)
                    .then((resp) => {
                        this.project.project_dates = resp.data.project_dates;
                    });

            }
            return true;
        }

        shouldShowBudgetOrGuests() {
            return this.shouldShowBudget() || this.shouldShowGuests();
        }

        shouldShowBudget() {
            return this.user.company.shouldShowProjectField('budget');
        }

        shouldShowGuests() {
            return this.user.company.shouldShowProjectField('guests');
        }

        _isExistingLabel(type, label) {
            return this.project.project_dates.find((date) => {
                return date[type] === label;
            });
        }

        _generateLocationLabel() {
            let index = 0;
            let labelField = 'location_label';
            let prefix = 'Project Location';

            // if there are multiple dates, find the last index
            if(this.project.project_dates) {

                let defaultLocationLabels = this._.filter(this.project.project_dates, (date) => {
                    return date[labelField] && date[labelField].startsWith(prefix) && date[labelField].match(/\d+/);
                }).map((date) => { return date[labelField]; });

                let indexes = defaultLocationLabels.map((label) => {
                    return Number(label.match(/\d+/));
                });

                if(indexes && indexes.length) {
                    index = this._.sortBy(indexes)[indexes.length - 1];
                }
            }

            index += 1;

            return `Project Location ${index}`;
        }

        _getDateTimeAnalyticEvent(property) {
            if (!this._dateTimeAnalyticsMap) {
                this._dateTimeAnalyticsMap = {
                    event_date: 'start date',
                    event_end_date: 'end date',
                    event_time_start: 'start time',
                    event_time_end: 'end time',
                    date_label: 'date label',
                    location_label: 'location label',
                    location: 'location address'
                };
            }

            return this._dateTimeAnalyticsMap[property];
        }
    };
}());
