common_module.constant('ModalDialogMappings', {

    Modals: {

        UploadCoverPhoto: {
            template: 'angular/app/modules/core/features/upload_photo/upload_photo_template.html',
            controller: 'UploadPhotoController as uploadPhotoVm'
        },

        UploadFiles: {
            template: 'angular/app/modules/core/features/upload_files/upload_files_template.html',
            controller: 'UploadFilesController as uploadFilesVm'
        },

        UploadCanvaAssets: {
            template: 'angular/app/modules/core/features/upload_canva_assets/upload_canva_assets_template.html',
            controller: 'UploadCanvaAssetsController as uploadCanvaAssetsVm'
        },

        FileTitleEditor: {
            template: 'angular/app/modules/core/features/workspace_file/file_title/file_title_template.html',
            controller: 'FileTitleController as fileTitleVm'
        },

        ChooseTitle: {
            template: 'angular/app/modules/core/features/workspace_file/choose_title/choose_title_template.html',
            controller: 'ChooseTitleController as chooseTitleVm'
        },

        TemplateSaveAs: {
            template: 'angular/app/modules/core/features/company_settings/templates/save_as_modal/template_save_as_template.html',
            controller: 'TemplateSaveAsController as templateSaveAsVm'
        },

        EmailEditor: {
            template: 'angular/app/modules/common/ui_components/email_editor/email_editor_template.html',
            controller: 'EmailEditorController as emailEditorVm'
        },

        ScheduleEmailSend: {
            template: 'angular/app/modules/common/ui_components/email_scheduler/schedule_email_send_template.html',
            controller: 'ScheduleEmailSendController as scheduleEmailSendVm'
        },

        AddWorkspaceParticipant: {
            template: 'angular/app/modules/core/features/add_user/new_add_user_template.html',
            controller: 'AddWorkspaceParticipantController as addUserVm'
        },

        NewAddCompanyTeamMember: {
            template: 'angular/app/modules/core/features/add_user/new_add_user_template.html',
            controller: 'NewAddCompanyTeamMemberController as addUserVm'
        },

        AssignWorkspaceToTeamMember: {
            template: 'angular/app/modules/core/features/team_members/select_team_members.html',
            controller: 'AssignWorkspaceToTeamMemberController as selectMemberVm'
        },

        AddTeamMemberToWorkspace: {
            template: 'angular/app/modules/core/features/team_members/select_team_members.html',
            controller: 'AddTeamMemberToWorkspaceController as selectMemberVm'
        },

        NewAddContact: {
            template: 'angular/app/modules/core/features/add_user/new_add_user_template.html',
            controller: 'NewAddContactController as addUserVm'
        },

        NewAddPreferredContact: {
            template: 'angular/app/modules/core/features/add_user/new_add_user_template.html',
            controller: 'NewAddPreferredContactController as addUserVm'
        },

        EditContact: {
            template: 'angular/app/modules/core/features/add_user/new_add_user_template.html',
            controller: 'EditContactController as addUserVm'
        },

        NewVendorReferral: {
            template: 'angular/app/modules/core/features/add_user/new_add_user_template.html',
            controller: 'NewVendorReferralController as addUserVm'
        },

        WorkspaceAdminMove: {
            template: 'angular/app/modules/core/features/add_user/new_add_user_template.html',
            controller: 'NewWorkspaceAdminMoveController as addUserVm'
        },

        CreateProject: {
            template: 'angular/app/modules/core/features/create_project/create_project_template.html',
            controller: 'CreateProjectController as createProjectVm'
        },

        GoogleContactsModal: {
            template: 'angular/app/modules/common/ui_components/google_contacts/google_contacts_modal_template.html',
            controller: 'GoogleContactsModalController as googleContactsVm'
        },

        AttachAsset:{
            template:'angular/app/modules/common/ui_components/attach_asset_modal/attach_asset_modal_template.html' ,
            controller: 'AttachAssetModalController as attachAssetModalVm'
        },

        BlockedVendor:{
            template:'angular/app/modules/common/ui_components/malkut_blocked_vendor/malkut_blocked_vendor_template.html' ,
            controller: 'BlockedVendorModalController as blockedVendorModalVm'
        },

        UnsentFile: {
            template: 'angular/app/modules/core/features/proposal/unsent_file_modal/unsent_file_template.html',
            controller: 'UnsentFileController as unsentFileVm'
        },

        UploadingFiles: {
            template: 'angular/app/modules/core/features/proposal/uploading_files_modal/uploading_files_template.html',
            controller: 'UploadingFilesController as uploadingFilesVm'
        },

        IntroTip: {
            template: 'angular/app/modules/common/ui_components/intro_tip/intro_tip_template.html',
            controller: 'IntroTipController as introTipVm'
        },

        Signature: {
            template: 'angular/app/modules/core/features/signatures/signature_modal/signature_modal_template.html',
            controller: 'SignatureModalController as signatureModalVm'
        },

        MalkutStatGraph: {
            template: 'angular/app/modules/malkut/features/prodev/trend_graph/trend_graph_template.html',
            controller: 'TrendGraphController as trendGraphVm'
        },

        ConnectCalendarModal: {
            template: 'angular/app/modules/core/features/calendar/calendar_modals/connect_calendar_template.html',
            controller: 'ConnectCalendarController as connectVm'
        },

        ConnectNylasCalendarModal: {
            template: 'angular/app/modules/core/features/calendar/calendar_modals/connect_nylas_calendar_template.html',
            controller: 'ConnectNylasCalendarController as connectNylasVm'
        },

        WorkspaceTypeIntro: {
            template: 'angular/app/modules/core/features/workspace/workspace_intro/workspace_type_intro_template.html',
            controller: 'WorkspaceTypeIntroController as workspaceTypeIntroVm'
        },

        ExpandImage: {
            template: 'angular/app/modules/common/ui_components/expand_image_modal/expand_image_modal_template.html',
            controller: 'ExpandImageController as expandImageVm'
        },

        GenericVideo: {
            template: 'angular/app/modules/core/features/onboarding/generic_video_template.html',
            controller: 'GenericVideoController as genericVideoVm'
        },

        TOSChanged: {
            template: 'angular/app/modules/core/features/onboarding/tos_changed_template.html',
            controller: 'TOSChangedController as tosChangedVm'
        },

        DisableAutoPay: {
            template: 'angular/app/modules/core/features/payments/auto_payments/disable_auto_pay_template.html',
            controller: 'DisableAutoPayController as autoPayVm'
        },
        ConfirmAutoPay: {
            template: 'angular/app/modules/core/features/payments/auto_payments/confirm_auto_pay_template.html',
            controller: 'ConfirmAutoPayController as autoPayVm'
        },

        TrialExtendedModal: {
            template: 'angular/app/modules/common/ui_components/trial_extended/trial_extended_modal.html',
            controller: 'TrialExtendedController as trialExtendedVm'
        },

        FraudStatusReason: {
            template: 'angular/app/modules/malkut/features/blocked_vendors/fraud_status_reason_template.html',
            controller: 'FraudStatusReasonController as fraudStatusReasonVm'
        },

        ShowS3EmailPreview: {
            template: 'angular/app/modules/malkut/features/preview_emails/s3_preview_email.html',
            controller: 'MailStatusesPreviewerController as mailStatusPreviewVm'
        },

        AddPaymentAdminEditModal: {
            template: 'angular/app/modules/malkut/features/transfers/add_payment_admin_edit_template.html',
            controller: 'AddPaymentAdminEditController as addPaymentAdminEditVm'
        },

        EditPreferredVendors: {
            template: 'angular/app/modules/core/features/preferred_vendors/edit_and_view_preferred_vendors_list_template.html',
            controller: 'EditPreferredVendorsListController as preferredVendorsListVm'
        },

        PVLCreationGuidanceModal: {
            template: 'angular/app/modules/core/features/preferred_vendors/pvl_creation_guidance_template.html',
            controller: 'PVLCreationGuidanceController as pvlCreationGuidanceVm'
        },

        ViewPreferredVendorsList: {
            template: 'angular/app/modules/core/features/preferred_vendors/edit_and_view_preferred_vendors_list_template.html',
            controller: 'ViewPreferredVendorsListController as preferredVendorsListVm'
        },
        ContactFormExamples: {
            template: 'angular/app/modules/core/features/contact_form/examples/contact_form_examples_template.html',
            controller: 'ContactFormExamplesController as contactFormExamplesVm'
        },
        EmbededContactForm: {
            template: 'angular/app/modules/core/features/contact_form/embeded_code/embeded_code_contact_form_template.html',
            controller: 'EmbededCodeContactFormController as embededCodeVm'
        },
        GiftCardPublishModal: {
            template: 'angular/app/modules/core/features/contact_form/gift_card_publish/gift_card_publish_template.html',
            controller: 'GiftCardPublishModalController as giftCardPublishModalVm'
        },
        PreviewContactForm: {
            template: 'angular/app/modules/core/features/contact_form/preview/preview_contact_form_template.html',
            controller: 'PreviewContactFormController as previewFormVm'
        },

        RescheduleProjectContainerModal: {
          template: 'angular/app/modules/core/features/reschedule_project_modal/reschedule_project_container_template.html',
          controller: 'RescheduleProjectContainerController as rescheduleProjectVm'
        },

        PauseProjectModal: {
            template: 'angular/app/modules/core/features/pause_project/pause_project_template.html',
            controller: 'PauseProjectController as pauseProjectTemplateVm'
        },

        ArchiveProjectModal: {
            template: 'angular/app/modules/core/features/archive_project/archive_project_template.html',
            controller: 'ArchiveProjectController as archiveProjectTemplateVm'
        },

        SubdomainConfigModal: {
            template: 'angular/app/modules/core/features/settings/company/client_portal/custom_domain_config_modal_template.html',
            controller: 'SubdomainConfigModalController as subdomainConfigVm'
        },

        BulkEmailsIntroModal: {
            template: 'angular/app/modules/common/ui_components/bulk_emails/bulk_emails_intro_modal.html',
            controller: 'BulkEmailsIntroModalController as bulkEmailsIntroModalVm'
        },

        EmailTemplatesModal: {
            template: 'angular/app/modules/common/ui_components/email_templates_modal/email_templates_modal.html',
            controller: 'EmailTemplatesModalController as emailTemplatesModalVm'
        },

        ContactFormMembers: {
            template: 'angular/app/modules/core/features/contact_form/members/contact_form_members_modal.html',
            controller: 'ContactFormMembersController as ContactFormMembersVm'
        },

        PipelineSettings: {
            template: 'angular/app/modules/core/features/pipeline/settings/pipeline_settings_modal_template.html',
            controller: 'PipelineSettingsController as pipelineSettingsVm'
        },

        ConfirmDeleteStage: {
            template: 'angular/app/modules/core/features/pipeline/settings/confirm_delete_stage_template.html',
            controller: 'ConfirmDeleteStageController as confirmDeleteVm'
        },

        ConfirmBrochureSelection: {
            template: 'angular/app/modules/core/features/workspace_file/brochure/confirm_brochure_selection_template.html',
            controller: 'ConfirmBrochureSelectionController as confirmBrochureSelectionVm'
        },

        SendBrochureWarning: {
            template: 'angular/app/modules/core/features/workspace_file/brochure/send_brochure_warning_template.html',
            controller: 'SendBrochureWarningController as sendBrochureWarningVm'
        },

        ConfirmFileExpiration: {
            template: 'angular/app/modules/core/features/workspace_file/file_settings/confirm_file_expiration_modal.html',
            controller: 'ConfirmFileExpirationController as confirmExpirationVm'
        },

        ChangeSubscription: {
            template: 'angular/app/modules/common/ui_components/change_subscription_plan_modal/change_subscription_plan_template.html',
            controller: 'ChangeSubscriptionController as changeSubscriptionVm'
        },

        NetworkOnboardingIntroductionModal: {
            template: 'angular/app/modules/network/features/onboarding/network_onboarding_introduction_template.html',
            controller: 'NetworkOnboardingIntroductionController as networkOnboardingIntroductionVm'
        },

        CalendarMeetingModal: {
            template: 'angular/app/modules/core/features/calendar/schedule_meeting_modal.html',
            controller: 'ScheduleMeetingModalController as scheduleMeetingVm'
        },

        EmailSendErrorModal: {
            template: 'angular/app/modules/core/features/emails/send_error_modal/email_send_error_modal.html',
            controller: 'EmailSendErrorModalController as emailSendErrorVm'
        },

        PausedOfferModal: {
            template: 'angular/app/modules/core/features/settings/company/subscription/paused_offer_modal.html',
            controller: 'PausedOfferModalController as pausedOfferVm'
        },

        ClientBotRecapModal: {
            template: 'angular/app/modules/core/features/workspace/clientbot_modal/clientbot_recap_modal.html',
            controller: 'ClientBotModalController as clientBotModalVm'
        },

        VideoCarouselModal: {
            template: 'angular/app/modules/core/features/video_carousel_modal/video_carousel_modal.html',
            controller: 'VideoCarouselModalController as videoCarouselModalVm'
        },

        TemplateExamplesModal: {
            template: 'angular/app/modules/core/features/template_examples_modal/template_examples_modal.html',
            controller: 'TemplateExamplesModalController as templateExamplesModalVm'
        },

        AddPromotionModal: {
            template: 'angular/app/modules/core/features/add_promotion_modal/add_promotion_modal.html',
            controller: 'AddPromotionModalController as addPromotionModalVm'
        },

        PvlRecommendationsModal: {
            template: 'angular/app/modules/core/features/preferred_vendors/modal_pvl_recommendations/pvl_recommendations_template.html',
            controller: 'PvlRecommendationsController as pvlRecommendVm'
        },

        NetworkImproveProfileModal: {
            template: 'angular/app/modules/network/features/profile/improve_profile/network_improve_profile_template.html',
            controller: 'NetworkImproveProfileController as networkImproveProfileVm'
        },

        NetworkDiscoverySettingsModal: {
            template: 'angular/app/modules/network/features/settings/network_discovery_settings_template.html',
            controller: 'NetworkDiscoverySettingsController as networkDiscoverySettingsVm'
        },

        EventSpacesModal: {
            template: 'angular/app/modules/core/features/event/event_spaces/event_spaces_template.html',
            controller: 'EventSpacesController as eventSpacesVm'
        },

        IntakeModal: {
            template: 'angular/app/modules/core/features/registration/intake_modal.html',
            controller: 'IntakeModalController as intakeModalVm'
        },

        MalkutTransferFundsFromHBModal: {
            template: 'angular/app/modules/common/ui_components/stripe/malkut_transfer_funds_modal_template.html',
            controller: 'MalkutTransferFundsModalController as malkutTransferFundsModalVm'
        },

        MalkutPullFundsFromMemberBankModal: {
            template: 'angular/app/modules/common/ui_components/stripe/malkut_pull_funds_modal_template.html',
            controller: 'MalkutPullFundsModalController as malkutPullFundsModalVm'
        },

        MalkutBankAccountInfoModal: {
            template: 'angular/app/modules/common/ui_components/stripe/malkut_bank_account_info_template.html',
            controller: 'MalkutBankAccountInfoController as malkutBankAccountInfoVm'
        },

        MalkutDisputedPaymentModal: {
            template: 'angular/app/modules/common/ui_components/stripe/malkut_disputed_payment_template.html',
            controller: 'MalkutDisputedPaymentController as malkutDisputedPaymentVm'
        },

        AddMalkutUserModal: {
            template: 'angular/app/modules/malkut/features/administration/add_malkut_user_modal.html',
            controller: 'AddMalkutUserController as addMalkutUserVm'
        },

        MalkutUpdateCompanyLocation: {
            template: 'angular/app/modules/malkut/features/update_company_location/update_company_location_template.html',
            controller: 'UpdateCompanyLocationController as updateCompanyLocationVm'
        },

        MalkutInjectTemplatesToCompany: {
            template: 'angular/app/modules/malkut/features/inject_templates/malkut_inject_templates_template.html',
            controller: 'MalkutInjectTemplatesController as malkutInjectTemplatesVm'
        },

        MalkutRequestDocumentsModal: {
            template: 'angular/app/modules/malkut/features/request_documents/malkut_request_documents_template.html',
            controller: 'MalkutRequestDocumentsController as malkutRequestDocumentsVm'
        },

        MalkutSelectTemplatesForPromo: {
            template: 'angular/app/modules/malkut/features/inject_templates/malkut_update_vendor_promo_inject_template.html',
            controller: 'MalkutSelectTemplatesForPromoController as malkutSelectTemplatesForPromoVm'
        },

        SendBatchEmails: {
            template: 'angular/app/modules/core/features/emails/batch_emails/batch_emails_modal/batch_emails_model_template.html',
            controller: 'BatchEmailsModalController as sendBatchEmailsVm'
        },

        ReferralModal: {
            template: 'angular/app/modules/core/features/invite/referral_modal_template.html',
            controller: 'ReferralModalController as referralModalVm'
        },
        LoginAsClientModal: {
            template: 'angular/app/modules/core/features/workspace_file/login_as_client/login_as_client_template.html',
            controller: 'LoginAsClientController as loginAsClientVm'
        },

        ConfirmGmailIntegrationNeeded: {
            template: 'angular/app/modules/core/features/general_confirm_modals/confirm_gmail_integration_needed_template.html',
            controller: 'ConfirmGmailIntegrationNeededController as confirmGmailVm'
        },

        TwoFactorAuth: {
            template: 'angular/app/modules/common/ui_components/phone_verification/two_factor_auth_modal_template.html',
            controller: 'TwoFactorAuthModalController as twoFactorAuthModalVm'
        },

        AddSecurityPhoneNumberModal: {
            template: 'angular/app/modules/core/features/settings/account/security_phone_number/add_security_phone_number_modal_template.html',
            controller: 'AddSecurityPhoneNumberModalController as addSecurityPhoneNumberModalVm'
        },

        PayWallModal: {
            template: 'angular/app/modules/core/features/onboarding/paywall/paywall_modal_template.html',
            controller: 'PayWallController as payWallVm'
        },

        NonUsaWarningModal: {
            template: 'angular/app/modules/core/features/onboarding/non_usa_warning/non_usa_warning_modal.html',
            controller: 'NonUsaWarningController as nonUsaWarningVm'
        },

        CreateManualReferralModal: {
            template: 'angular/app/modules/malkut/features/referrals/commissions/create_manual_referral_modal_template.html',
            controller: 'CreateManualReferralModalController as createManualReferralVm'
        },

        UpdateReferralCodeModal: {
            template: 'angular/app/modules/malkut/features/referrals/user/update_referral_code_modal_template.html',
            controller: 'UpdateReferralCodeModalController as updateReferralCodeVm'
        },

        VerifyBillingInfoModal: {
            template: 'angular/app/modules/malkut/features/cs/companies/verify_billing_info_modal.html',
            controller: 'VerifyBillingInfoModalController as verifyBillingInfoVm'
        },

        UpdateContactFormUrlModal: {
            template: 'angular/app/modules/malkut/features/cs/companies/update_contact_form_url_modal_template.html',
            controller: 'UpdateContactFormUrlModalController as updateContactFormUrlVm'
        },

        ApproveReferralCommissionModal: {
            template: 'angular/app/modules/malkut/features/referrals/commissions/approve_referral_commission_modal_template.html',
            controller: 'ApproveReferralCommissionModalController as approveReferralCommissionVm'
        },

        BlockNewCouponModal: {
            template: 'angular/app/modules/malkut/features/referrals/manage_coupons/block_new_coupon_modal_template.html',
            controller: 'BlockNewCouponModalController as $ctrl'
        },

        EditReferralCampaignModal: {
            template: 'angular/app/modules/malkut/features/referrals/campaigns/edit_referral_campaign_modal_template.html',
            controller: 'EditReferralCampaignModalController as editReferralCampaignVm'
        },

        OnBoardingFourPanelsModal: {
            template: 'angular/app/modules/core/features/onboarding/on_boarding_four_panels/on_boarding_four_panels_modal_template.html',
            controller: 'OnBoardingFourPanelsController as onBoardingFourPanelsVm'
        },

        MessagePopupModal: {
            template: 'angular/app/modules/common/ui_components/popup_alert/popup_message_template.html',
            controller: 'PopupMessageController as popupMessageVm'
        },

        ConnectProjectModal: {
            template: 'angular/app/modules/core/features/calendar/calendar_modals/connect_project_modal/connect_project_modal.html',
            controller: 'ConnectProjectModalController as connectProjectVm'
        },

        mWebConnectProjectModal: {
            template: 'angular/app/modules/core/features/calendar/calendar_modals/connect_project_modal/connect_project_modal_m.html',
            controller: 'mWebConnectProjectModalController as mWebConnectProjectVm'
        },

        TimeTrackerSampleModal: {
            template: 'angular/app/modules/core/features/time_tracker/time_tracker_sample_modal/time_tracker_sample_modal_template.html',
            controller: 'TimeTrackerSampleModalController as timeTrackerSampleVm'
        },

        TimeTrackerEntryModal: {
            template: 'angular/app/modules/core/features/time_tracker/time_tracker_entry_modal/time_tracker_entry_modal_template.html',
            controller: 'TimeTrackerEntryModalController as timeTrackerEntryVm'
        },

        TimeTrackerViewAllModal: {
            template: 'angular/app/modules/core/features/time_tracker/time_tracker_view_all_modal/time_tracker_view_all_modal_template.html',
            controller: 'TimeTrackerViewAllModalController as timeTrackerAllVm'
        },

        TimeTrackerToolInvoiceModal: {
            template: 'angular/app/modules/core/features/time_tracker/time_tracker_tool_invoice_modal/time_tracker_tool_invoice_modal_template.html',
            controller: 'TimeTrackerToolInvoiceController as timeTrackerToolInvoiceVm'
        },

        StartFromFileWizardModal: {
            template: 'angular/app/modules/core/features/workspace_file/create_file_wizard/start_from_file_wizard_modal_template.html',
            controller: 'StartFromFileWizardModalController as startFromFileWizardModalVm'
        },

        openCreateFirstProjectModal: {
            template: 'angular/app/modules/core/features/create_event/create_first_project_template.html',
            controller: 'CreateFirstProjectController as createFirstProjectVm'
        },

        BadgePillTypesModal: {
            template: 'angular/app/modules/core/features/badge_pill_types/badge_pill_types_modal/badge_pill_types_modal.html',
            controller: 'BadgePillTypesModalController as badgePillsModalVm'
        },

        MarkAsPaidModal: {
            template: 'angular/app/modules/core/features/workspace_file/payment/mark_as_paid/mark_as_paid_template.html',
            controller: 'MarkAsPaidController as markAsPaidVm'
        },

        SelectEmailServiceProvider: {
            template: 'angular/app/modules/core/features/settings/company/integrations/nylas/select_email_provider.html',
            controller: 'SelectEmailProviderController as selectEmailProviderVm'
        },

        RefundPaymentModal: {
            template: 'angular/app/modules/core/features/workspace_file/payment/refund/refund_payment_template.html',
            controller: 'RefundPaymentController as refundPaymentVm'
        },

        DebitCardModal: {
            template: 'angular/app/modules/core/features/workspace_file/payment/debit/debit_card_template.html',
            controller: 'DebitCardController as debitCardVm'
        },

        WaiveLateFeeModal: {
            template: 'angular/app/modules/core/features/reports/payments/waive_late_fee_template.html',
            controller: 'WaiveLateFeeController as waiveLateFeeVm'
        },

        InstantDepositModal: {
            template: 'angular/app/modules/core/features/workspace_file/payment/instant_deposit/instant_deposit_modal_template.html',
            controller: 'InstantDepositModalController as instantDepositModalControllerVm'
        },

        InstantDepositPromoteAutoModal: {
            template: 'angular/app/modules/core/features/workspace_file/payment/instant_deposit/instant_deposit_promote_auto_modal.html',
            controller: 'InstantDepositPromoteAutoModalController as instantDepositPromoteAutoModalControllerVm'
        },

        BrandingInspirationModal: {
            template: 'angular/app/modules/core/features/settings/company/branding/inspiration_modal/company_branding_inspiration.html',
            controller: 'BrandingInspirationModalController as brandingInspirationVm'
        },

        FacebookLeadsModal: {
            template: 'angular/app/modules/core/features/facebook_leads/facebook_leads_directive_template.html',
            controller: 'FacebookLeadsDirectiveController as facebookLeadsVm'
        },
        FacebookLeadsIntroModal: {
            template: 'angular/app/modules/core/features/facebook_leads/facebook_leads_intro_modal_template.html',
            controller: 'FacebookLeadsIntroModalController as facebookLeadsIntroModalVm'
        },
        ClientFlowModal: {
            template: 'angular/app/modules/core/features/workspace_file/client_flow_modal/client_flow_modal.html',
            controller: 'ClientFlowModalController as clientFlowModalVm'
        },

        CreditCardConfirm: {
            template: 'angular/app/modules/core/features/payments/credit_card_confirm_modal/credit_card_confirm_modal.html',
            controller: 'CreditCardConfirmController as creditCardConfirmVm'
        },

        ContactFormExplanationVideoModal: {
            template: 'angular/app/modules/core/features/contact_form/video_modal/contact_form_explanation_video_modal.html',
            controller: 'ContactFormExplanationVideoModalController as cfVideoModalVm'
        },

        AddCompanyModal: {
            template: 'angular/app/modules/core/features/brands/add_company_modal_template.html',
            controller: 'AddCompanyModalController as addCompanyModalVm'
        },

        ClientPortalSendLink: {
            template: 'angular/app/modules/core/features/settings/company/client_portal/send_link/client_portal_send_link_modal.html',
            controller: 'ClientPortalSendLink as clientPortalSendLinkVm'
        },

        ClientPortalDemo: {
            template: 'angular/app/modules/core/features/settings/company/client_portal/demo_modal/client_portal_demo_modal.html',
            controller: 'ClientPortalDemo as clientPortalDemoVm'
        },

        PipelineOnboardingModal: {
            template: 'angular/app/modules/core/features/pipeline/pipeline_onboarding_modal/pipeline_onboarding_modal_template.html',
            controller: 'PipelineOnboardingModalController as pipelineOnboardingModalVm',
        },

        CustomUrlModal: {
            template: 'angular/app/modules/core/features/settings/company/client_portal/custom_url_modal/custom_url_modal_template.html',
            controller: 'CustomUrlModalController as customUrlModalVm'
        },
        EditPaymentReminderEmailModal: {
            template: 'angular/app/modules/core/features/settings/company/preferences/edit_payment_reminder_email_modal_template.html',
            controller: 'EditPaymentReminderEmailController as editPaymentReminderEmailVm'
        },
        ConnectBankAccount: {
            template: 'angular/app/modules/core/features/bank_account/connect_bank_account_modal.html',
            controller: 'ConnectBankAccountModalController as connectBankAccountModalVm'
        },
        SearchV2Modal: {
            template: 'angular/app/modules/core/features/search/v2/search_v2_modal.html',
            controller: 'SearchV2ModalController as searchV2ModalVm'
        },
        ProjectDetailsNotesModal: {
            template: 'angular/app/modules/core/features/workspace/project_details/project_details_notes_modal.html',
            controller: 'ProjectDetailsNotesModalController as projectDetailsNotesModalVm'
        },
        ProjectDetailsCustomizeModal: {
            template: 'angular/app/modules/core/features/settings/company/project_details/modal/project_details_customize_modal.html',
            controller: 'ProjectDetailsCustomizeModalController as projectDetailsCustomizeModalVm'
        },

        BirthdayModal: {
            template: 'angular/app/modules/core/features/dashboard/birthday_modal/birthday_modal_template.html',
            controller: 'BirthdayModalController as birthdayModalVm'
        },

        AddBankAccountOwnerModal: {
            template: 'angular/app/modules/core/features/bank_account/add_bank_account_owner_template.html',
            controller: 'AddBankAccountOwnerController as addBankAccountOwnerVm'
        },

        UploadCompanyAdditionalDocuments: {
            template: 'angular/app/modules/core/features/bank_account/upload_company_additional_documents_template.html',
            controller: 'UploadAdditionalDocumentsController as uploadAdditionalDocumentsVm'
        },

        BankAccountBusinessInfo: {
            template: 'angular/app/modules/core/features/bank_account/bank_account_business_info_template.html',
            controller: 'BankAccountBusinessInfoController as bankAccountBusinessInfoVm'
        },

        MalkutBankOwnerDetailsModal: {
            template: 'angular/app/modules/malkut/features/cs/user_details/malkut_bank_owner_details_template.html',
            controller: 'MalkutBankOwnerController as malkutBankOwnerVm'
        },

        MwebBlockModal: {
            template: 'angular/app/modules/core/features/mweb_block_modal/mweb_block_modal.html',
            controller: 'MwebBlockModalController as mwebBlockVm'
        },

        InteractiveOnboardingExplainBrandingModal: {
            template: 'angular/app/modules/core/features/onboarding/interactive_onboarding/explain_branding_modal/explain_branding_modal.html',
            controller: 'InteractiveOnboardingExplainBrandingModalController as intOnbExpBrandingModalVm'
        },

        InteractiveOnboardingExplainServicesModal: {
            template: 'angular/app/modules/core/features/onboarding/interactive_onboarding/explain_services_modal/explain_services_modal.html',
            controller: 'InteractiveOnboardingExplainServicesModalController as intOnbExpServicesModalVm'
        },

        MwebPreviewBrandingModalController: {
            template: 'angular/app/modules/core/features/onboarding/flows_onboarding/flows_onboarding_wizard/steps/branding/mweb_preview_branding_modal/mweb_preview_branding_modal.html',
            controller: 'MwebPreviewBrandingModalController as mwebPreviewBrandingModalVm'
        },

        EditDayAvailabilityModal: {
            template: 'angular/app/modules/core/features/calendar/scheduling/edit_day_availability_modal/edit_day_availability_modal_template.html',
            controller: 'EditDayAvailabilityModalController as editDayAvailabilityVm'
        },

        CreatedSessionModal: {
            template: 'angular/app/modules/core/features/calendar/scheduling/created_session_modal/created_session_modal_template.html',
            controller: 'CreatedSessionModalController as createdSessionModalVm'
        },

        TemplatesExplainModal: {
            template: 'angular/app/modules/core/features/company_settings/templates/templates_explain_modal/templates_explain_modal.html',
            controller: 'TemplatesExplainModalController as templatesExplainModalVm'
        },

        UpcomingPaymentsModal: {
            template: 'angular/app/modules/core/features/payments/upcoming_payments_modal/upcoming_payments_modal.html',
            controller: 'UpcomingPaymentsModalController as upcomingPaymentsVm'
        },

        MigrateUserModal: {
            template: 'angular/app/modules/core/features/migrate_user_to_flows_modal/migrate_user_modal.html',
            controller: 'MigrateUserModalController as $ctrl'
        },

        TeamMembersOtamModal: {
            template: 'angular/app/modules/core/features/team_members_otam_modal/team_members_otam_modal.html',
            controller: 'TeamMembersOtamModalController as $ctrl'
        },

        PreMigrateUserModal: {
            template: 'angular/app/modules/core/features/pre_migrate_user_to_flows_modal/pre_migrate_user_modal.html',
            controller: 'PreMigrateUserModalController as $ctrl'
        },

        RateBooking: {
            template: 'angular/app/modules/core/features/rate_booking/rate_booking_template.html',
            controller: 'RateBookingController as rateBookingVm'
        },

        MalkutUpdateFeatureVariationModal: {
            template: 'angular/app/modules/malkut/features/pricing_tiers/update_feature_variation_modal_template.html',
            controller: 'UpdateFeatureVariationModalController as $ctrl'
        },

        MalkutAddProIntegrationModal: {
            template: 'angular/app/modules/malkut/features/cs/user_details/malkut_add_pro_integration_modal_template.html',
            controller: 'MalkutAddProIntegrationModalController as $ctrl'
        },

        MalkutRecurringLegacyFilesDetailsModal: {
            template: 'angular/app/modules/malkut/features/cs/user_details/malkut_recurring_legacy_files_details_modal_template.html',
            controller: 'MalkutRecurringLegacyFilesDetailsModalController as recurringLegacyFilesModalVm'
        },

        MalkutUserFeatureVariationsModal: {
            template: 'angular/app/modules/malkut/features/cs/user_details/malkut_user_feature_variations_modal_template.html',
            controller: 'MalkutUserFeatureVariationsModalController as userFeatureVariationsModalVm'
        },

        MalkutUpdateUserFeatureVariationModal: {
            template: 'angular/app/modules/malkut/features/cs/user_details/malkut_update_user_feature_variation_modal_template.html',
            controller: 'MalkutUpdateUserFeatureVariationModalController as $ctrl'
        },

        StartTrialAsClientModal: {
            template: 'angular/app/modules/core/features/settings/account/start_trial_as_client/start_trial_as_client_modal_template.html',
            controller: 'StartTrialAsClientModalController as startTrialAsClientModalVm'
        },

        ChangePasswordModal: {
            template: 'angular/app/modules/core/features/login/change_expired_password/change_expired_password_modal_template.html',
            controller: 'ChangeExpiredPasswordModalController as changeExpiredPasswordModalVm'
        }
    }

});
